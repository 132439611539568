let to_be_imported = 5;
let imported = 0;

function cb(m, v, set_default = true)
{
    imported++;
    
    if(m && v)
        window[v] = set_default ? m.default : m;

    if(to_be_imported == imported)
        document.dispatchEvent(new Event("loaded_pkgs"));
}

import("bootstrap").then(m => { cb(m, "bootstrap", false) });
import("feather-icons").then(m => { cb(m, "feather") });
import("jquery").then(m =>  { cb(m, "$") });
import("list.js").then(m => { cb(m, "List") });
import("moment").then(m =>  { cb(m, "moment") });

function requireAll(r) { r.keys().forEach(r); }

document.addEventListener("loaded_pkgs", (event) =>
{
    requireAll(require.context('./index/', true, /\.js$/));
    feather.replace();
});

import("../styles/style.scss");